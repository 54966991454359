import MapboxMap, { FillLayer, FullscreenControl, Layer, LineLayer, LngLatBoundsLike, MapLayerMouseEvent, MapRef, NavigationControl, SkyLayer, Source, useControl } from 'react-map-gl';
import NavBar from "../NavBar"
import { useCallback, useMemo, useRef, useState } from "react";
import { FeatureCollection } from 'geojson';
import '../css/Map.css'
import '../css/ModelHex.css'
import { schemeSet1 } from 'd3-scale-chromatic';
import {ArcLayer} from '@deck.gl/layers/typed';
import {MapboxOverlay, MapboxOverlayProps} from '@deck.gl/mapbox/typed';
import {cellToLatLng} from 'h3-js';
import { useMeasure } from "@uidotdev/usehooks";
import Dropdown from '../components/Dropdown';
import DropdownMultiple from '../components/DropdownMultiple';
import /*DividedBarPlotFiltered,*/ { CategoryDataPoint } from '../components/DividedBarPlotFiltered';
import PieChart from '../components/PieChart';

function DeckGLOverlay(props: MapboxOverlayProps & {
  interleaved?: boolean;
}) {
  const overlay = useControl<MapboxOverlay>(() => new MapboxOverlay(props));
  overlay.setProps(props);
  return null;
}

export function ModelHexMap() {

  const mapRef = useRef<MapRef>(null);
  const [leftSidebarRef, leftSidebarMeasure] = useMeasure()

  type Location = {
    lng: number
    lat: number
    zoom: number
    bounds: LngLatBoundsLike
  }

  const kelowna: Location = useMemo(() => { 
    return{
      lng: -119.5333,
      lat: 49.9313,
      zoom: 9.08,
      bounds: [[-120.75, 49.5], [-118.25, 50.4]],
    }
  }, [])
  
  const vancouver: Location = useMemo(() => {
    return {
      lng: -122.9307,
      lat: 49.1910,
      zoom: 9.82,
      bounds: [[-124, 48.5], [-121.75, 49.7]],
    }
  }, [])

  const travelModes: Record<string, string> = useMemo(() => {
    return {
      'Car': 'car',
      'Public Transportation': 'pt',
      'Bike': 'bike',
      'Walk': 'walk'
    }
  }, [])

  const activities: Record<string, string> = useMemo(() => {
    return {
      'Home': 'Home',
      'Work': 'Work',
      'Recreation': 'Recreation',
      'Social': 'Social',
      'Personal': 'Personal',
      'Pickup/Dropoff': 'PickupDropoff',
      'Restaurant': 'Restaurant',
      'IntHome': 'IntHome',
      'Shopping': 'Shopping',
    }
  }, [])

  const [currentLocation, setCurrentLocation] = useState<Location>(kelowna)
  const [lng, setLng] = useState(currentLocation.lng);
  const [lat, setLat] = useState(currentLocation.lat);
  const [zoom, setZoom] = useState(currentLocation.zoom);
  const [maxBounds, setMaxBounds] = useState<LngLatBoundsLike | undefined>(undefined);
  const [mapFlying, setMapFlying] = useState(false)
  
  const [modelData, setModelData] = useState<FeatureCollection | null>(null)
  const [maxHexCount, setMaxHexCount] = useState<number>(0)
  const [tripConnectionData, setTripConnectionData] = useState<{hex: string, count: number, hex_center: {x: number, y: number}}[] | null>(null)
  const [statData, setStatData] = useState<{statName: string, data: CategoryDataPoint[]}[] | null>(null)

  const [locationDropdownValue, setLocationDropdownValue] = useState<string | null>('Kelowna')
  const [thresholdDropdownValue, setThresholdDropdownValue] = useState<string | null>('Threshold');
  const [directionDropdownValue, setDirectionDropdownValue] = useState<string | null>('Going to')
  const [startActivityFilter, setStartActivityFilter] = useState<string[]>([])
  const [endActivityFilter, setEndActivityFilter] = useState<string[]>([])
  const [travelModeFilter, setTravelModeFilter] = useState<string[]>([])
  const [incomeFilter, setIncomeFilter] = useState<string[]>([])
  const [ageFilter, setAgeFilter] = useState<string[]>([])
  const [educationFilter, setEducationFilter] = useState<string[]>([])
  const [sexFilter, setSexFilter] = useState<string[]>([])
  const [filters, setFilters] = useState<{filterName: string, values: string[]}[]>([])

  const [selectedHex, setSelectedHex] = useState<string | null>(null)

  const [sidebarExpanded, setSidebarExpanded] = useState<boolean>(false)

  useMemo(() => {
    setFilters([
      {filterName: 'startActivity', values: startActivityFilter},
      {filterName: 'endActivity', values: endActivityFilter},
      {filterName: 'travelMode', values: travelModeFilter},
      {filterName: 'income', values: incomeFilter},
      {filterName: 'education', values: educationFilter},
      {filterName: 'sex', values: sexFilter},
    ])
  }, [educationFilter, endActivityFilter, incomeFilter, sexFilter, startActivityFilter, travelModeFilter])

  useMemo(() => {
    console.log(filters.map((filter) => {return (filter.values.length && {[filter.filterName]: filter.values})}))
    fetch('api/geoDatabase/getModelTripCountByHexFiltered?' + new URLSearchParams({
        zoom: '8',
        ...(directionDropdownValue && {direction: directionDropdownValue.toString()}),
        ...(startActivityFilter.length && {startActivity: startActivityFilter.map(value => activities[value]).toString()}),
        ...(endActivityFilter.length && {endActivity: endActivityFilter.map(value => activities[value]).toString()}),
        ...(travelModeFilter.length && {travelMode: travelModeFilter.map(value => travelModes[value]).toString()}),
        ...(incomeFilter.length && {income: incomeFilter.toString()}),
        ...(educationFilter.length && {education: educationFilter.toString()}),
        ...(sexFilter.length && {sex: sexFilter.toString()}),
      }))
      .then(resp => resp.ok ? resp.json() : Promise.reject(resp))
      .then(json => {setModelData(json); return json})
      .catch(err => console.error('Could not load data', err));
  }, [filters, activities, directionDropdownValue, educationFilter, endActivityFilter, incomeFilter, sexFilter, startActivityFilter, travelModeFilter, travelModes]);

  useMemo(() => {
    if (modelData == null) return 0;
    console.log(modelData.features.map(f=>f.properties?.['count']).reduce((a, b) => {return Math.max(a, b)}))
    setMaxHexCount(modelData.features.map(f=>f.properties?.['count']).reduce((a, b) => {return Math.max(a, b)}))
  }, [modelData, setMaxHexCount])

  useMemo(() => {
    console.log(modelData)
  }, [modelData])
  
  useMemo(() => {
    if (selectedHex == null) {setTripConnectionData(null); return;}
    fetch('api/geoDatabase/getModelTripConnectionsFromHexFiltered?' + new URLSearchParams({
      hex: selectedHex,
      zoom: '8',
      ...(directionDropdownValue && {direction: directionDropdownValue.toString()}),
      ...(startActivityFilter.length && {startActivity: startActivityFilter.map(value => activities[value]).toString()}),
      ...(endActivityFilter.length && {endActivity: endActivityFilter.map(value => activities[value]).toString()}),
      ...(travelModeFilter.length && {travelMode: travelModeFilter.map(value => travelModes[value]).toString()}),
      ...(incomeFilter.length && {income: incomeFilter.toString()}),
      ...(educationFilter.length && {education: educationFilter.toString()}),
      ...(sexFilter.length && {sex: sexFilter.toString()}),
    }))
    .then(resp => resp.json())
    .then(json => {setTripConnectionData(json); return json})
    .catch(err => console.error('Could not load data', err));
  }, [activities, directionDropdownValue, educationFilter, endActivityFilter, incomeFilter, selectedHex, sexFilter, startActivityFilter, travelModeFilter, travelModes])

  useMemo(() => {
    console.log(tripConnectionData)
  }, [tripConnectionData])

  useMemo(() => {
    fetch('api/geoDatabase/getModelTripStats?' + new URLSearchParams({
      ...(selectedHex && {hex: selectedHex}),
      zoom: '8',
      ...(directionDropdownValue && {direction: directionDropdownValue.toString()}),
      ...(startActivityFilter.length && {startActivity: startActivityFilter.map(value => activities[value]).toString()}),
      ...(endActivityFilter.length && {endActivity: endActivityFilter.map(value => activities[value]).toString()}),
      ...(travelModeFilter.length && {travelMode: travelModeFilter.map(value => travelModes[value]).toString()}),
      ...(incomeFilter.length && {income: incomeFilter.toString()}),
      ...(educationFilter.length && {education: educationFilter.toString()}),
      ...(sexFilter.length && {sex: sexFilter.toString()}),
    }))
    // .then(resp => resp.json() as unknown as {statName: string, data: CategoryDataPoint[]}[])
    .then(resp => resp.json() as unknown as {statName: string, data: {categorytitle: string, value: string, filteredvalue: string}[]}[])
    //TODO: fixx this horrendous line
    .then(json => {console.log(json); return json.map(stat => {return {statName: stat.statName, data: stat.data.map(data => {return {categoryTitle: data.categorytitle, value: parseInt(data.value), filteredValue: (data.filteredvalue == null ? 0 : parseInt(data.filteredvalue))}})}})})
    .then(json => {setStatData(json); return json})
    .catch(err => console.error('Could not load data', err));
  }, [activities, directionDropdownValue, educationFilter, endActivityFilter, incomeFilter, selectedHex, sexFilter, startActivityFilter, travelModeFilter, travelModes])

  useMemo(() => {
    console.log(statData)
  }, [statData])

  useMemo(() => {
    if(!mapFlying){
      if(locationDropdownValue == 'Kelowna' && currentLocation != kelowna){
        setCurrentLocation(kelowna)
      }
      if(locationDropdownValue == 'Vancouver' && currentLocation != vancouver){
        setCurrentLocation(vancouver)
      }
    }
  }, [currentLocation, kelowna, locationDropdownValue, mapFlying, vancouver])

  const skyLayer: SkyLayer = {
    id: 'sky',
    type: 'sky',
    paint: {
      'sky-type': 'atmosphere',
      'sky-atmosphere-sun': [0.0, 0.0],
      'sky-atmosphere-sun-intensity': zoom
    }
  };

  const outlineLayer: LineLayer = {
    id: 'outlineLayer',
    type: 'line',
    paint: {
      'line-color': '#000000',
      'line-width': [
        'interpolate', 
        ['linear'], 
        ["zoom"],
        9, 0.3, 
        12, 2,
      ],
    }
  }

  const hexDataLayer: FillLayer = useMemo(() =>{
    const layer: FillLayer = {
      id: 'hexDataLayer',
      type: 'fill',
      paint: {
        'fill-color': ['interpolate-hcl', 
          ['linear'],
          ['/', ['get', 'count'], maxHexCount / 2],
          0, ['rgb', 255, 255, 255],
          0.5, schemeSet1[1]
        ],
        'fill-opacity': 0.8,
      }
    }
    return layer
  }, [maxHexCount])

  const arcLayer = useMemo(() => {
    return new ArcLayer({
      id: 'arcLayer',
      data: (tripConnectionData == null || selectedHex == null) ? [] : tripConnectionData.map((row) => {
        const startLatLng = cellToLatLng(selectedHex)
        return {
          source: [
            startLatLng[1], 
            startLatLng[0], 
            350
          ],
          target: [
            row.hex_center.x,
            row.hex_center.y,
          ],
          count: row.count,
        }
      }),
      getSourcePosition: (d) => {
        return d.source
      },
      getTargetPosition: d => d.target,
      getSourceColor: () => directionDropdownValue == 'Going to' ? [200, 0, 0] : [0, 200, 0],
      getTargetColor: () => directionDropdownValue == 'Going to' ? [0, 200, 0] : [200, 0, 0],
      getWidth: (d) => {
        if (thresholdDropdownValue == "Threshold" && tripConnectionData && tripConnectionData?.length > 30 && d.count < 20) return 0;
        return Math.max(1, d.count/50)
      },
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tripConnectionData, thresholdDropdownValue]);

  useMemo(() => {
    if(mapRef.current == undefined){return}
    setSelectedHex(null)
    setMaxBounds(undefined)
    setMapFlying(true)
    mapRef.current?.flyTo({
      center: {
        lng: currentLocation.lng, 
        lat: currentLocation.lat
      }, 
      zoom: currentLocation.zoom,
      pitch: 0,
      bearing: 0
    })
  }, [currentLocation])

  function onMapMoveEnd(){
    if(mapFlying){
      setMaxBounds(currentLocation.bounds)
      setMapFlying(false)
      // mapRef.current?.easeTo({
      //   pitch: 0,
      //   bearing: 0
      // })
    }
  }

  function onMapClick(e: MapLayerMouseEvent){
    if (!e.features || e.features?.length == 0){
      setSelectedHex(null)
      return
    }
    console.log(e.features[0].properties?.hex)
    setSelectedHex(e.features[0].properties?.hex)
  }

  const onMapLoad = useCallback(() => {
    mapRef.current?.getMap().addSource('mapbox-dem', {
      'type': 'raster-dem',
      'url': 'mapbox://mapbox.mapbox-terrain-dem-v1',
      'tileSize': 512,
    });
    mapRef.current?.getMap().setTerrain({
      'source': 'mapbox-dem',
    });
    mapRef.current?.on('move', () => {
      if(mapRef.current){
        setLng(mapRef.current.getCenter().lng);
        setLat(mapRef.current.getCenter().lat);
        setZoom(mapRef.current.getZoom());
      }
    });
  }, []);

  return (
    <div className='map-container' style={{pointerEvents: mapFlying ? 'none' : 'auto'}}>
      <MapboxMap
        id='map'
        ref={mapRef}
        interactive={true}
        interactiveLayerIds={['hexDataLayer']}
        onClick={onMapClick}
        onLoad={onMapLoad}
        onMoveEnd={onMapMoveEnd}
        mapboxAccessToken="pk.eyJ1Ijoic3R1bWNnIiwiYSI6ImNrcDRtdHE1ZjBiYTkyeHQ4NG5sc3VpM2MifQ.N_cSsEXlTBk7q92Xdpfbug"
        initialViewState={{
          longitude: lng,
          latitude: lat,
          zoom: zoom,
        }}
        maxBounds={maxBounds}
        mapStyle="mapbox://styles/mapbox/light-v9"
      >
        <Layer {...skyLayer} />
        {
          modelData != null && hexDataLayer != null && (
            <Source type="geojson" data={modelData}>
              <Layer {...hexDataLayer}/>
              <Layer {...outlineLayer}/>
            </Source>
          )
        }
        <DeckGLOverlay interleaved={true} layers={[arcLayer]} />
        <NavigationControl position='bottom-right' visualizePitch={true}/>
        <FullscreenControl position='bottom-right'/>
        <div className='map-controls map-controls-grid-parent'>
          <div ref={leftSidebarRef} className={`left-sidebar ${!sidebarExpanded && 'left-sidebar-collapsed'}`}>
            {!sidebarExpanded &&(
                <div onClick={() => setSidebarExpanded(true)}>↘</div>
            )}
            {sidebarExpanded && statData && (
              <>
                <div style={{'flexShrink': '0'}}>
                  <div style={{'cursor': 'pointer', 'userSelect': 'none'}} onClick={() => setSidebarExpanded(false)}>↖</div>
                  <p style={{'fontSize': '35px', 'lineHeight': '35px', 'fontWeight': 'bold', 'margin': '10px 0px'}}>{selectedHex ? `Trips ${directionDropdownValue} Selected Hex:` : 'All Trips:'}</p>
                </div>
                <div style={{'fontSize': '16px', 'overflow': 'scroll', 'height': '100%', 'paddingLeft': leftSidebarMeasure.width ? leftSidebarMeasure.width * 0.05: 0}}>
                  {statData.map(stat => {return leftSidebarMeasure.width && (
                    <>
                      <h3 style={{'margin': 0}}>{stat.statName}</h3>
                      {/* <DividedBarPlotFiltered
                        height={leftSidebarMeasure.width * 0.4}
                        width={leftSidebarMeasure.width * 0.9}
                        data={stat.data}
                      /> */}
                      <PieChart
                        radius={leftSidebarMeasure.width * 0.45}
                        data={stat.data}
                      />
                    </>
                  )})}
                </div>
              </>
            )}
          </div>
          <div className='filters-container'>
            <div className='dropdown-container'>
              <Dropdown
                name={'Location'}
                options={['Kelowna', 'Vancouver']}
                value={locationDropdownValue}
                onChange={(value) => setLocationDropdownValue(value)}
                anyOption={false}
              />
            </div>
            <div className='dropdown-container'>
              <Dropdown
                name={'Threshold'}
                options={['Threshold', 'No Threshold']}
                value={thresholdDropdownValue}
                onChange={(value) => setThresholdDropdownValue(value)}
                anyOption={false}
              />
            </div>
            <div className='dropdown-container'>
              <Dropdown
                name={'Direction'}
                options={['Going to', 'Coming from']}
                value={directionDropdownValue}
                onChange={(value) => setDirectionDropdownValue(value)}
                anyOption={false}
              />
            </div>
            <div className='dropdown-container'>
              <DropdownMultiple
                name={'Start Activity Type'} 
                options={Object.keys(activities)}
                values={startActivityFilter}
                onChange={(values) => setStartActivityFilter(values)}
              />
            </div>
            <div className='dropdown-container'>
              <DropdownMultiple
                name={'End Activity Type'} 
                options={Object.keys(activities)}
                values={endActivityFilter}
                onChange={(values) => setEndActivityFilter(values)}
              />
            </div>
            <div className='dropdown-container'>
              <DropdownMultiple
                name={'Travel Mode'}
                options={Object.keys(travelModes)}
                values={travelModeFilter}
                onChange={(value) => setTravelModeFilter(value)}
              />
            </div>
            <div className='dropdown-container'>
              <DropdownMultiple
                name={'Income'} 
                options={['1', '2', '3', '4']}
                values={incomeFilter}
                onChange={(values) => setIncomeFilter(values)}
              />
            </div>
            <div className='dropdown-container'>
              <DropdownMultiple
                name={'Age'} 
                options={['17-25', '25-45', '45-65', '65+']}
                values={ageFilter}
                onChange={(values) => setAgeFilter(values)}
              />
            </div>
            <div className='dropdown-container'>
              <DropdownMultiple
                name={'Education'} 
                options={['1', '2', '3', '4']}
                values={educationFilter}
                onChange={(values) => setEducationFilter(values)}
              />
            </div>
            <div className='dropdown-container'>
              <DropdownMultiple
                name={'Sex'} 
                options={['1', '2']}
                values={sexFilter}
                onChange={(values) => setSexFilter(values)}
              />
            </div>
          </div>
        </div>
      </MapboxMap>
    </div>
  )
}

function ModelHex() {
  return (
    <>
      <NavBar active='ModelHex'/>
      <div className='content-fullscreen'>
        <ModelHexMap/>
      </div>
    </>
  )
}

export default ModelHex
