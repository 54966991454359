import { useEffect, useState } from "react"
import HourlyStackedAreaChart from "./components/HourlyStackedAreaChart";

function Scratch() {
  const [modelData, setModelData] = useState<{hex: string, data: {total: number[], activityCounts: {[key: string]: number[]}}}[] | null>(null);
  const [selectedHexes, ] = useState<string[]>([])
  const [selectedHexesData, setSelectedHexesData] = useState<{total: number[], activityCounts: {[key: string]: number}[]}>({total: [], activityCounts: []})
  const [hoveredActivity, setHoveredActivity] = useState<string>('');
  const [currentTimeIndex, setCurrentTimeIndex] = useState<number> (0);

  useEffect(() => {
    fetch('api/geoDatabase/getModelHourlyHexPopulation?' + new URLSearchParams({
      zoom: '8',
      location: 'ok'
    }))
    .then(resp => resp.json())
    .then(json => setModelData(json));
  }, []);

  useEffect(() => {
    // console.log(modelData)
    if(modelData == null) return
    // setSelectedHexes([modelData[0].hex, modelData[2].hex])
  }, [modelData])

  useEffect(() => {
    const selectedHexRows = modelData?.filter(row => selectedHexes.includes(row.hex) || selectedHexes.length == 0).map(row => {return {
      total: row.data.total, 
      activityCounts: row.data.total.map((_, index) => {
        const countsAtTimeIndex: {[key: string]: number} = {}
        Object.keys(row.data.activityCounts).forEach((key) => {
          countsAtTimeIndex[key] = row.data.activityCounts[key][index]
        })
        return countsAtTimeIndex
      })
    }});
    setSelectedHexesData(selectedHexRows?.reduce((a, b) => {
      return{
        total: a.total.map((value, index) => value + b.total[index]).slice(0, 25),
        activityCounts: a.activityCounts.map((value, index) => {
          Object.keys(value).forEach((key) => {
            value[key] += b.activityCounts[index][key]
          })
          return value
        }).slice(0, 25)
      }
    }) ?? {total: [], activityCounts: []});
  }, [modelData, selectedHexes])
  return (
    <div style={{'backgroundColor': 'white', 'display': 'inline-block', 'margin': '50px', 'borderRadius': '5px'}}>
      {selectedHexesData.total.length > 0 && (
        <HourlyStackedAreaChart
          data= {selectedHexesData}
          width={600}
          height={450}
          currentTimeIndex={currentTimeIndex}
          onSelectTimeIndex={setCurrentTimeIndex}
          hoveredActivity={hoveredActivity}
        setHoveredActivity={setHoveredActivity}
        />
      )}
    </div>
  )
}

export default Scratch
