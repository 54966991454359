import { AxisBottom } from '@visx/axis';
import { scaleBand, scaleLinear } from "@visx/scale";
import { Group } from "@visx/group";
import { Bar } from '@visx/shape';
import { useMemo } from "react";

function BarChart({width, height, data}: {width: number, height: number, data: {name: string, value: number, color: string}[]}){
  const xMax = width * 0.8;
  const yMax = height * 0.8;

  const xScale = useMemo(() => scaleBand({
    range: [0, xMax],
    domain: data.map(d => d.name),
    padding: 0.1
  }), [data, xMax])
  const yScale = useMemo(() => scaleLinear({
    range: [0, yMax],
    domain: [0, Math.max(...data.map(d => d.value)) + 1]
  }), [data, yMax])
  return (
    <svg width={width} height={height}>
      <rect width={width} height={height} fill='white'/>
      <Group left={width * 0.1} top={height * 0.05}>
        {data.map((d) => {
          const barWidth = xScale.bandwidth();
          const barHeight = yMax - (yScale(d.value) ?? 0);
          const barX = xScale(d.name);
          const barY = yMax - barHeight;
          return(
            <>
              <Bar
                key={`bar-${d.name}`}
                x={barX}
                y={barHeight}
                width={barWidth}
                height={barY}
                fill={d.color}
              />
            </>
          )
        })}
        <AxisBottom
          scale={xScale}
          label="TravelMode"
          top={yMax}
          numTicks={data.length}
        />
      </Group>
    </svg>
  )
}

export default BarChart