import { ModelHex2Map } from './maps/ModelHex2'
import { ModelHex3Map } from './maps/ModelHex3'
import { HashLink } from 'react-router-hash-link'
import { Link } from 'react-router-dom'
import Logo from './components/Logo.tsx'
import './css/NewHome.css'
import { ModelHexMap } from './maps/ModelHex'
import { animate, motion } from 'framer-motion'
import { useRef, useState } from 'react'
import { HexDistancesMap } from './maps/HexDistances.tsx'

const scrollWithOffset = (el: HTMLElement) => {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  const yOffset = -50; 
  window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
}

function Home(){
  const [menuBarAnimationProgress, setmenuBarAnimationProgress] = useState<number>(1);
  const logoHeight = 120;
  const menuSpacerRef = useRef<HTMLDivElement>(null)
  const menuBarRef = useRef<HTMLDivElement>(null)
  const menuBarParentRef = useRef<HTMLDivElement>(null)
  return (
    <>
      <div ref={menuBarParentRef} className='menu-bar-parent'>
        <div style={{
          'position': 'relative',
          'top': (1 - Math.max(menuBarAnimationProgress*2 - 1, 0)) * -50,
          'opacity': Math.max(menuBarAnimationProgress*2 - 1, 0)
        }}>
          <Logo height={logoHeight}/>
        </div>
        <motion.div 
          ref={menuSpacerRef}
          style={{height: logoHeight}} 
          className='menu-spacer'
          onViewportLeave={() => {
            animate(menuBarAnimationProgress, 0, {duration: 1, onUpdate: value => setmenuBarAnimationProgress(value)})}
          }
          onViewportEnter={() => {
            animate(menuBarAnimationProgress, 1, {duration: 1, onUpdate: value => setmenuBarAnimationProgress(value)})}
          }
          viewport={{amount:'all'}}
        />
          <div 
            ref={menuSpacerRef} 
            className='menu-bar' 
            style={{position: 'relative', left: -(1 - Math.min(menuBarAnimationProgress*1.25, 1)) * (
              (menuSpacerRef.current?.offsetLeft ?? 0) + (menuSpacerRef.current?.offsetWidth ?? 0) - ((menuBarRef.current?.offsetWidth ?? 0) + (menuBarParentRef.current?.offsetWidth ?? 0) / 2)
            )
          }}>
            <div className='menu-link-container'>
              <HashLink className='menu-link' to='/#maps' scroll={scrollWithOffset}>Maps</HashLink>
            </div>
            <div className='menu-link-container'>
              <HashLink className='menu-link' to='/#about' scroll={scrollWithOffset}>About</HashLink>
            </div>
            <div className='menu-link-container'>
              <HashLink className='menu-link' to='/#data' scroll={scrollWithOffset}>Data</HashLink>
            </div>
            <div className='menu-link-container'>
              <HashLink className='menu-link' to='/#prototypes' scroll={scrollWithOffset}>Prototypes</HashLink>
            </div>
            <div className='menu-link-container'>
              <HashLink className='menu-link' to='/#contact' scroll={scrollWithOffset}>Contact</HashLink>
            </div>
            <div className='menu-link-container'>
              <Link className='menu-link' to='/Login'>Admin</Link>
            </div>
          </div>
        </div>
        <div>
        <div style={{'width':'60%', 'textAlign':'center', 'padding':'20px 20%'}}>
        <p>Climate Mobility Research Network is a project involving researchers at the UBC Okanagan and Vancouver campuses.</p>
        <p> This interactive geoportal provides public access to data and 
            research findings from the UBC/CAAF project. It will offer direct access for professionals and 
            researchers and interactive visualizations for the general public. The geoportal focus 
            on presenting information on transportation and emissions in an intuitive, accessible, and 
            interactive format by organizing spatial data from various sources. The project emphasizes 
            effective data visualization and interactivity and aims to improve 
            visualization and access tools for complex spatialized data.</p>
            
        </div>
        


        <div id='maps'>
        <div style={{'width':'80%', 'textAlign':'center', 'padding':'20px 10%', 'display': 'flex', 'justifyContent': 'space-between'}}>
            <div style={{'display':'flex', 'width': '25%','flexDirection':'column', 'justifyContent':'right', 'textAlign': 'center'}}>
              <p>
                Map of Kelowna region (Okanagan, you can switch to Vancouver Metro) showing the average distance that people travel in a day based on simulated travel.
                The info boxes in the top right can be expanded to see different stats broken down by transportation type as well as used to filter the map data based 
                on transportation type. Hexs on the map can be selected to show an extruded bar with a breakdown of transportation types for that hex and the info box 
                stats will be updated accordingly.
              </p>
            </div>
            <div style={{'height': '60vh', 'width': '70%', 'backgroundColor': '#2a74c8', 'padding': '5px'}}>
              <HexDistancesMap/>
            </div>
          </div>
          <div style={{'width':'80%', 'textAlign':'center', 'padding':'20px 10%', 'display': 'flex', 'justifyContent': 'space-between'}}>
            <div style={{'height': '60vh', 'width': '70%', 'backgroundColor': '#2a74c8', 'padding': '5px'}}>
              <ModelHex2Map/>
            </div>
            <div style={{'display':'flex', 'width': '25%','flexDirection':'column', 'justifyContent':'left', 'textAlign': 'center'}}>
            <p>Map of Kelowna region (Okanagan, you can switch to Vancouver Metro) with each extruded 
              hexagon representing the number of people in that area at the selected time of 
              day. Here one hexagon is selected and the 
            diferent colors show a breakdown of what activites people in that location are doing. There is 
            also a corresponding staked area chart showing the activity breakdown of the selected hex over 
            the course of the day. The user can brush over the hexagons of the chart fo highlight 
            coresponding data points.</p>
            <p>If two hexagons are selected for comparison, with the line chart on the right 
            showing each locations population change over the course of the day. The time slider, controls
             on the left, or the chart and be interacted with to animate the extruded hexagons over
              the course of the simulated day</p>
            </div>
          </div>
          <div style={{'width':'80%', 'textAlign':'center', 'padding':'20px 10%', 'display': 'flex', 'justifyContent': 'space-between'}}>
            <div style={{'display':'flex', 'width': '25%','flexDirection':'column', 'justifyContent':'right', 'textAlign': 'center'}}>
              <p>
                Map of Kelowna region (Okanagan, you can switch to Vancouver Metro) showing simulated travel data. The arcs represent all trips going 
                too the selected hexagon. Thicker lines represent more peopl. There are options to filter trips based 
                on various factors such as travel mode and start or end activity type.
              </p>
            </div>
            <div style={{'height': '60vh', 'width': '70%', 'backgroundColor': '#2a74c8', 'padding': '5px'}}>
              <ModelHex3Map/>
            </div>
          </div>
          <div style={{'width':'80%', 'textAlign':'center', 'padding':'20px 10%', 'display': 'flex', 'justifyContent': 'space-between'}}>
            <div style={{'height': '60vh', 'width': '70%', 'backgroundColor': '#2a74c8', 'padding': '5px'}}>
              <ModelHexMap/>
            </div>
            <div style={{'display':'flex', 'width': '25%','flexDirection':'column', 'justifyContent':'left', 'textAlign': 'center'}}>
            <p>Map of Kelowna region (Okanagan, you can switch to Vancouver Metro) showing simulated travel data. The arcs represent all trips going 
          too the selected hexagon. Thicker lines represent more peopl. There are options to filter trips based 
          on various factors such as travel mode and start or end activity type.</p>
            </div>
          </div>
        </div>
        <div id='about' style={{'width':'60%', 'textAlign':'center', 'padding':'20px 20%'}}>
          <h1 style={{'color': '#2a74c8'}}>About</h1>
          <div style={{'width':'60%', 'textAlign':'left', 'padding':'20px 20%'}}>
            <p>The research and development is supported by:</p>
            <p><a href="https://uitr.ok.ubc.ca/">
            UBC integrated Transportation Research (UiTR)Laboratory</a> , is a transportation
             and land use research facility at the University of British Columbia's Okanagan (UBCO) campus. </p>
             <p><a href="https://ireach.mech.ubc.ca/">
             The iREACH Lab </a> conducts research on the development and application of real-world-based tools 
             to quickly and quantitatively assess the impact of our policy and technology decisions on 
             air pollution and climate outcomes.  </p>
            <p><a href="https://icer.ok.ubc.ca/">
            The Institute for Community Engaged Research (ICER)</a> is an academic research 
            institute located at the University of British Columbia's Okanagan campus in Kelowna, BC, Canada.</p>
        </div>
          
          <p> The CAAF (Climate Action and Awareness Fund) UBC project is a five-year interdisciplinary research initiative aiming 
            to develop strategies to reduce transportation emissions in urban and rural areas of British Columbia. The project will 
            address limitations in current data and models used for emission reduction policies, taking into account the impact of 
            the COVID-19 pandemic on travel behavior. Stakeholder engagement from various levels of government, transit agencies, 
            indigenous communities, and the public is emphasized to ensure the project's findings are relevant and applicable to 
            real-world settings.</p>
            <p>The project has key objectives:</p>
            <p>1. Collect novel travel behavior data through surveys.</p>
            <p>2. Develop land use and vehicle ownership models.</p>
            <p>3. Create advanced travel demand forecasting models.</p>
            <p>4. Quantify vehicular emissions for policy scenarios.</p>
            <p>5. Develop an interactive online geoportal to share findings and engage stakeholders.</p>
            <p>he project aims to develop a geoportal to provide access to data, models, and visualization tools for informed 
            decision-making. It will also recommend emissions mitigation strategies based on findings and focus on Metro Vancouver
             and Central Okanagan. The project team includes researchers and professionals from UBC.</p>T
             <p>The team will collaborate closely with partner organizations to ensure the project's success. The project proposal 
            highlights the commitment to equity, diversity, and inclusion. The budget outlines the allocation of funds towards 
            research activities, personnel, data collection, knowledge sharing, and the development of the geoportal. The proposal 
            also outlines a comprehensive evaluation plan to track progress and ensure the project's objectives are met. </p>
            <p>The CAAF project aims to contribute significantly to Canada's climate change science landscape by providing 
            valuable data, models, and tools for understanding and mitigating transportation emissions.</p>
        </div>

        <div id='data' style={{'width':'60%', 'textAlign':'center', 'padding':'20px 20%'}}>
          <h1 style={{'color': '#2a74c8'}}>Data</h1>
          <h3>Information about data sources </h3>
          <p><a href="https://www12.statcan.gc.ca/census-recensement/2021/dp-pd/index-eng.cfm">Canada Census 2021 Data Products</a></p>
          <p><a href="https://www2.gov.bc.ca/gov/content/data/geographic-data-services">
          GeoBC - Geographic data and services - British Columbia</a></p>
          <p><a href="https://data-donnees.az.ec.gc.ca/data?lang=en">
          Environment and Climate Change Canada (ECCC) Data Catalog</a></p>
          <p><a href="https://www.canada.ca/en/services/environment/weather/climatechange/funding-programs/climate-action-awareness-fund.html">
          Climate Action and Awareness Fund (CAAF)</a></p>
          <p><a href="https://download.geofabrik.de/">
          Geofabrik - Open Street Maps data extracts</a></p>
          <h3>Access Data (link to request access / login)</h3>
          

        </div>
      </div>  

          <div id='prototypes' style={{width: '40%', textAlign: 'left', padding: '20px 20%'}}>
            <h1 style={{color: '#2a74c8'}}>Prototypes</h1>
            <p>Links to previous maps:</p>
            
            <div style={{position: 'relative', paddingLeft: '50px', marginBottom: '20px'}}>
              <div style={{
                position: 'absolute',
                left: '25px',
                top: 0,
                bottom: 0,
                width: '2px',
                backgroundColor: '#AFEEEE',
              }}></div>
              
              <div style={{position: 'relative', marginBottom: '30px'}}>
                <div style={{
                  position: 'absolute',
                  left: '0',
                  top: '0',
                  width: '15px',
                  height: '15px',
                  backgroundColor: '#40E0D0',
                  borderRadius: '50%',
                }}></div>
                <h3 style={{marginLeft: '30px'}}>
                  <Link className='menu-link' to='/Map'>1 - Plot Map</Link>
                </h3>
                <p style={{marginLeft: '30px'}}>Bar Plot Map and Extruded Dissemination Area Examples</p>
              </div>

              <div style={{position: 'relative', marginBottom: '30px'}}>
                <div style={{
                  position: 'absolute',
                  left: '0',
                  top: '0',
                  width: '15px',
                  height: '15px',
                  backgroundColor: '#00CED1',
                  borderRadius: '50%',
                }}></div>
                <h3 style={{marginLeft: '30px'}}>
                  <Link className='menu-link' to='/Census'>2 - Dissemination Areas</Link>
                </h3>
                <p style={{marginLeft: '30px'}}>Dissemination areas representation (Statcanada Census)</p>
              </div>

              <div style={{position: 'relative', marginBottom: '30px'}}>
                <div style={{
                  position: 'absolute',
                  left: '0',
                  top: '0',
                  width: '15px',
                  height: '15px',
                  backgroundColor: '#20B2AA',
                  borderRadius: '50%',
                }}></div>
                <h3 style={{marginLeft: '30px'}}>
                  <Link className='menu-link' to='/ModelHex'>3 - H3 - Hexagon Area Stat</Link>
                </h3>
                <p style={{marginLeft: '30px'}}>Mapped H3 statistics</p>
              </div>

              <div style={{position: 'relative', marginBottom: '30px'}}>
                <div style={{
                  position: 'absolute',
                  left: '0',
                  top: '0',
                  width: '15px',
                  height: '15px',
                  backgroundColor: '#008B8B',
                  borderRadius: '50%',
                }}></div>
                <h3 style={{marginLeft: '30px'}}>
                  <Link className='menu-link' to='/ModelHex2'>4 - Time Animated</Link>
                </h3>
                <p style={{marginLeft: '30px'}}>Daily Populations Animation</p>
              </div>

              <div style={{position: 'relative', marginBottom: '30px'}}>
                <div style={{
                  position: 'absolute',
                  left: '0',
                  top: '0',
                  width: '15px',
                  height: '15px',
                  backgroundColor: '#008080',
                  borderRadius: '50%',
                }}></div>
                <h3 style={{marginLeft: '30px'}}>
                  <Link className='menu-link' to='/ModelHex3'>5 - Multi-Trip</Link>
                </h3>
                <p style={{marginLeft: '30px'}}>Multi-Trips interactive map</p>
              </div>
            </div>
          </div>


        <div id='contact' style={{'width':'60%', 'textAlign':'center', 'padding':'20px 20%'}}>
          <h1 style={{'color': '#2a74c8'}}>Contact</h1>
          <p>Dr. Mahmudur Fatmi - Centre for Transportation and Land Use Research (CeTLUR) </p>
          <p>Dr. Naomi Zimmerman - Energy, Air, Climate & Health (iREACH) lab </p>
          <p>Dr. Jon Corbett - Spatial Information for Community Engagement (SpICE) Lab</p>
          <p>Dr. Khalad Hasan - Human-Computer Interaction (HCI) lab</p>
          <p>Dr. Andrea MacNeill - UBC Planetary Healthcare Lab (PHcL)</p>


          
        </div>
     
    </>
  )
}

export default Home
