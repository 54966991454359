export const tab20Colors = [
  '#1f77b4',
  '#aec7e8',
  '#ff7f0e',
  '#ffbb78',
  '#2ca02c',
  '#98df8a',
  '#d62728',
  '#ff9896',
  '#9467bd',
  '#c5b0d5',
  '#8c564b',
  '#c49c94',
  '#e377c2',
  '#f7b6d2',
  '#7f7f7f',
  '#c7c7c7',
  '#bcbd22',
  '#dbdb8d',
  '#17becf',
  '#9edae5',
]
//https://eleanormaclure.wordpress.com/wp-content/uploads/2011/03/colour-coding.pdf
export const kellyColorsHex = [
  '#FFB300', // Vivid Yellow
  '#803E75', // Strong Purple
  '#FF6800', // Vivid Orange
  '#A6BDD7', // Very Light Blue
  '#C10020', // Vivid Red
  '#CEA262', // Grayish Yellow
  '#817066', // Medium Gray

  // The following don't work well for people with defective color vision
  '#007D34', // Vivid Green
  '#F6768E', // Strong Purplish Pink
  '#00538A', // Strong Blue
  '#FF7A5C', // Strong Yellowish Pink
  '#53377A', // Strong Violet
  '#FF8E00', // Vivid Orange Yellow
  '#B32851', // Strong Purplish Red
  '#F4C800', // Vivid Greenish Yellow
  '#7F180D', // Strong Reddish Brown
  '#93AA00', // Vivid Yellowish Green
  '#593315', // Deep Yellowish Brown
  '#F13A13', // Vivid Reddish Orange
  '#232C16', // Dark Olive Green
  ]


export function getTab20ByIndex({index, secondary = false}: {index: number, secondary?: boolean}): string{
  return tab20Colors[((index < 10 ? index : (index % 10) + Math.floor(index / 10)) * 2) + (secondary ? 1 : 0)];
}

export function getTab20SecondaryFromPrimary(color: string): string{
  return tab20Colors[tab20Colors.indexOf(color) + 1]
}

export function hexStringToRGBArray(hex: string): [number, number, number]{
  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)
  return [r, g, b]
}

function componentToHex(c: number) {
  const hex = c.toString(16);
  return hex.length == 1 ? "0" + hex : hex;
}

export function rgbArrayToHexString(rgb: [number, number, number]): string {
  return "#" + componentToHex(rgb[0]) + componentToHex(rgb[1]) + componentToHex(rgb[2]);
}

// input: h as an angle in [0,360] and s,l in [0,1] - output: r,g,b in [0,255]
export function hslToRgb(hsl: [number, number, number]): [number, number, number]
{
   const a = hsl[2]*Math.min(hsl[2],1-hsl[2]);
   const f= (n: number,k=(n+hsl[0]/30)%12) => hsl[2] - a*Math.max(Math.min(k-3,9-k,1),-1);
   return [Math.min(Math.floor(f(0)*256),255), Math.min(Math.floor(f(8)*256),255), Math.min(Math.floor(f(4)*256),255)];
}

// in: r,g,b in [0,255], out: h in [0,360) and s,l in [0,1]
export function rgbToHsl(rgb: [number, number, number]): [number, number, number] {
  const r = rgb[0]/255
  const g = rgb[1]/255
  const b = rgb[2]/255
  const v=Math.max(r,g,b), c=v-Math.min(r,g,b), f=(1-Math.abs(v+v-c-1)); 
  const h= c && ((v==r) ? (g-b)/c : ((v==g) ? 2+(b-r)/c : 4+(r-g)/c)); 
  return [60*(h<0?h+6:h), f ? c/f : 0, (v+v-c)/2];
}

export function hslToHex(h: number, s: number, l: number): string{
  l /= 100;
  const a = s * Math.min(l, 1 - l) / 100;
  const f = (n: number) => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color).toString(16).padStart(2, '0');   // convert to Hex and prefix "0" if needed
  };
  return `#${f(0)}${f(8)}${f(4)}`;
}

export function rgbChangeSaturation(rgb: [number, number, number], saturation: number): [number, number, number]{
  const hsl = rgbToHsl(rgb);
  return hslToRgb([hsl[0], saturation, hsl[2]])
}
// export function getFullHueColorMapColor(index: number, divisions: number, saturation: number, brightnes: number): string{
//   const hue = Math.round((110 / divisions) * index) + 60
//   return hslToHex(hue, saturation, brightnes);
// }

export function getFullHueColorMapColor(index: number, divisions: number, saturation: number, brightnes: number): string{
  const hue = Math.round((360 / divisions) * index)
  return hslToHex(hue, saturation, brightnes);
}

export function createHueMapFromPieData(data: number[]): number[]{
  if(data.length == 0) return []
  const colorMap = new Array(data.length).fill(0)
  const sortedData = data.map((value, index) => ({value: value, index: index})).sort((a, b) => b.value - a.value)
  // const dataTotal = data.reduce((a, b) => a + b)
  // let runningTotal = 0
  for(let i = 0; i < sortedData.length; i++){
    // colorMap[sortedData[i].index] = (((runningTotal + (sortedData[i].value / 2)) / dataTotal) * 320) - 40
    colorMap[sortedData[i].index] = (i/sortedData.length * 360)
    // runningTotal += sortedData[i].value
  }
  return colorMap
}