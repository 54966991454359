import MapboxMap, { FullscreenControl, Layer, LngLatBoundsLike, LngLatLike, MapRef, NavigationControl, SkyLayer, useControl } from 'react-map-gl';
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {MapboxOverlay, MapboxOverlayProps} from '@deck.gl/mapbox/typed';
import {H3HexagonLayer} from '@deck.gl/geo-layers/typed';
import {GeoJsonLayer} from '@deck.gl/layers/typed';
import {Feature} from 'geojson';
import colormap from 'colormap';
import { animate } from 'framer-motion';
import Dropdown from '../components/Dropdown';
import '../css/Map.css';
import '../css/ModelHex.css';
import { cellToBoundary } from 'h3-js';
import { useTooltip, TooltipWithBounds } from '@visx/tooltip';
import {rgbArrayToHexString, rgbChangeSaturation} from '../utils/ColorUtils';
import Button from '../components/Button';
import BarChart from '../components/BarChart';
import { useMeasure } from '@uidotdev/usehooks';

function DeckGLOverlay(props: MapboxOverlayProps & {
  interleaved?: boolean;
}) {
  const overlay = useControl<MapboxOverlay>(() => new MapboxOverlay(props));
  overlay.setProps(props);
  return null;
}

export function HexDistancesMap() {


  const mapRef = useRef<MapRef>(null);
  const [mapMeasureRef, mapMeasure] = useMeasure();

  type Location = {
    lng: number
    lat: number
    zoom: number
    bounds: LngLatBoundsLike
  }

  const kelowna: Location = useMemo(() => { 
    return{
      lng: -119.5333,
      lat: 49.9313,
      zoom: 9.08,
      bounds: [[-120.75, 49.5], [-118.25, 50.4]],
    }
  }, [])
  
  const vancouver: Location = useMemo(() => {
    return {
      lng: -122.9307,
      lat: 49.1910,
      zoom: 9.82,
      bounds: [[-124, 48.5], [-121.75, 49.7]],
    }
  }, [])

  const travelModes: {[key: string]: string} = useMemo(() => {
    return {
      'walk': 'walk',
      'pt': 'transit',
      'car': 'car',
      'bike': 'bike',
      'ap': 'passenger'
    }
  }, [])

  const travelModeColors: {[key: string]: [number, number, number]} = useMemo(() => {
    return {
      'walk': [0, 150, 10],
      'pt': [240, 160, 0],
      'car': [210, 0, 0],
      'bike': [0, 0, 245],
      'ap': [155, 0, 175]
    }
  }, [])

  // kg, from https://ourworldindata.org/travel-carbon-footprint
  const travelModeEmissions: {[key: string]: number} = useMemo(() => {
    return {
      'walk': 0.02,
      'pt': 0.1,
      'car': 0.17,
      'bike': 0.02,
      'ap': 0.09
    }
  }, [])

  const [locationDropdownValue, setLocationDropdownValue] = useState<string | null>('Kelowna')
  const [zoomLevelDropdownValue, setZoomLevelDropdownValue] = useState<string>('8')
  const [currentLocation, setCurrentLocation] = useState<Location>(kelowna)
  const [lng, setLng] = useState(currentLocation.lng);
  const [lat, setLat] = useState(currentLocation.lat);
  const [zoom, setZoom] = useState(currentLocation.zoom);
  const [maxBounds, setMaxBounds] = useState<LngLatBoundsLike | undefined>(undefined);
  const [mapFlying, setMapFlying] = useState<boolean>(false);

  const [modelData, setModelData] = useState<{hex: string, population: number, data: {[key: string]: number[]}}[] | null>(null);

  const {
    tooltipData, tooltipLeft, tooltipTop, tooltipOpen, showTooltip, hideTooltip,
  } = useTooltip<{total: number, travelModeData: {travelMode: string, value: number} | null}>();
  
  const [hoveredHex, setHoveredHex] = useState<string | null>(null)
  const [selectedHexes, setSelectedHexes] = useState<string[]>([])
  const [hexHoveredTravelMode, setHexHoveredTravelMode] = useState<string | null>(null)
  const [barHexsAnimateStatus, setBarHexesAnimateStatus] = useState<{[key: string]: number}>({})
  
  const [selectedTravelModes, setSelectedTravelModes] = useState<string[]>([])
  const [hoveredTravelMode, setHoveredTravelMode] = useState<string | null>(null)

  const [infoBoxData, setInfoBoxData] = useState<{[key: string]: {
    distPercent: number,
    tripsPercent: number,
    distancePerTrip: number,
    c02Percent: number
  }} | null>(null);

  const [barChartData, setBarChartData] = useState<{
    name: string,
    value: number,
    color: string
  }[] | null>(null);
  const [currentBarChartStat, setCurrentBarChartStat] = useState<string>('dist');

  const infoBoxMinimizedSize = 30;
  const infoBoxExpandedSize = 180;
  const [infoBoxesExpanded, setInfoBoxesExpanded] = useState<boolean>(false)
  const [infoBoxesAnimateStatus, setInfoBoxesAnimateStatus] = useState<number>(0)
  const [infoBoxesSize, setInfoBoxesSize] = useState<number>(infoBoxMinimizedSize)
  useEffect(() => {
    setInfoBoxesSize(infoBoxMinimizedSize + ((infoBoxExpandedSize - infoBoxMinimizedSize) * infoBoxesAnimateStatus))
  }, [infoBoxesAnimateStatus])
  const [chartBoxExpanded, setChartBoxExpanded] = useState<boolean>(false)
  const [chartBoxAnimationSatus, setChartBoxAnimationStatus] = useState<number>(0)
  const [chartBoxHeight, setChartBoxHeight] = useState<number>(infoBoxMinimizedSize * 2)
  const [chartBoxWidth, setChartBoxWidth] = useState<number>(infoBoxMinimizedSize * 2)
  useEffect(() => {
    setChartBoxHeight(infoBoxMinimizedSize*2 + (((infoBoxExpandedSize*2) - (infoBoxMinimizedSize*2)) * chartBoxAnimationSatus))
    setChartBoxWidth(infoBoxMinimizedSize*2 + (((infoBoxExpandedSize*3) - (infoBoxMinimizedSize*2)) * chartBoxAnimationSatus))
  }, [chartBoxAnimationSatus])

  useEffect(() => {
    fetch('api/geoDatabase/getModelHourlyHexDistances?'  + new URLSearchParams({
      zoom: zoomLevelDropdownValue,
      location: locationDropdownValue == 'Kelowna' ? 'ok' : 'va'
    }))
    .then(resp => resp.ok ? resp.json() : Promise.reject(resp))
    .then(json => setModelData(json))
    .catch(err => console.error('Could not load data', err));
  }, [zoomLevelDropdownValue, locationDropdownValue]);

  useEffect(() => {
    if(modelData == null){
      setInfoBoxData(null)
      return
    }
    const filteredData = modelData.filter(row => selectedHexes.includes(row.hex) || selectedHexes.length == 0);
    const totalDist = filteredData.map(hexData => hexData.data['cum_dist'].at(-1) ?? 0).reduce((sum, a) => sum + a, 0);
    const totalTrips = filteredData.map(hexData => hexData.data['cum_trip_count'].at(-1) ?? 0).reduce((sum, a) => sum + a, 0);

    const distByTravelMode: {[key: string]: number} = {};
    const tripsByTravelMode: {[key: string]: number} = {};
    const c02ByTravelMode: {[key: string]: number} = {};
    Object.keys(travelModes).forEach(travelMode => {
      distByTravelMode[travelMode] = filteredData.map(hexData => hexData.data[`cum_dist_${travelMode}`].at(-1) ?? 0).reduce((sum, a) => sum + a, 0);
      tripsByTravelMode[travelMode] = filteredData.map(hexData => hexData.data[`cum_trip_count_${travelMode}`].at(-1) ?? 0).reduce((sum, a) => sum + a, 0);
      c02ByTravelMode[travelMode] = distByTravelMode[travelMode] * travelModeEmissions[travelMode];
    })

    const totalC02 = Object.keys(travelModes).map(travelMode => c02ByTravelMode[travelMode]).reduce((sum, a) => sum + a, 0);

    const dataByTravelMode: {[key: string]: {
      distPercent: number,
      tripsPercent: number,
      distancePerTrip: number,
      c02Percent: number
    }} = {};

    const barChartDataList: {
      name: string,
      value: number,
      color: string
    }[] = [];

    Object.keys(travelModes).forEach(travelMode => {
      dataByTravelMode[travelMode] = {
        distPercent: distByTravelMode[travelMode] / totalDist,
        tripsPercent: tripsByTravelMode[travelMode] / totalTrips,
        distancePerTrip: distByTravelMode[travelMode] / tripsByTravelMode[travelMode],
        c02Percent: c02ByTravelMode[travelMode] / totalC02
      }

      let barChartValue = 0
      switch(currentBarChartStat) {
        case 'dist':
          barChartValue = distByTravelMode[travelMode]/1000;
          break;
        case 'trips':
          barChartValue = tripsByTravelMode[travelMode]
          break;
        case 'c02':
          barChartValue = c02ByTravelMode[travelMode]
          break;
      }

      barChartDataList.push({
        name: travelModes[travelMode], 
        value: barChartValue,
        color: rgbArrayToHexString(travelModeColors[travelMode])
      })
    })
    setInfoBoxData(dataByTravelMode)
    setBarChartData(barChartDataList)

  }, [selectedHexes, modelData, travelModes, travelModeEmissions, travelModeColors, currentBarChartStat])

// Math.round((modelData.filter(row => selectedHexes.includes(row.hex) || selectedHexes.length == 0).map(hexData => hexData.data[`cum_dist_${travelMode}`].at(-1) ?? 0).reduce((sum, a) => sum + a, 0) / 1000) * travelModeEmissions[travelMode])} KgCo2`

  const hexLayer = useMemo(() => {  
    if (modelData == null) return null
    let cmap = colormap({
      colormap: [
        {index: 0, rgb: [235, 235, 100]},
        {index: 0.5, rgb: [34, 149, 170]},
        {index: 1, rgb: [10, 25, 85]},
      ],
      nshades: 100, 
      format: 'rgba'
    });
    const travelModes = hoveredTravelMode ? [hoveredTravelMode] : selectedTravelModes
    if(travelModes.length == 1){
      cmap = colormap({
        colormap: [
          {index: 0, rgb: [135, 135, 135]},
          {index: 0.25, rgb: rgbChangeSaturation(travelModeColors[travelModes[0]], 0.5)},
          {index: 1, rgb: travelModeColors[travelModes[0]]},
        ],
        nshades: 100, 
        format: 'rgba'
      });
    }
    else if (travelModes.length > 1){
      cmap = colormap({
        colormap: [
          {index: 0, rgb: [255, 255, 255]},
          {index: 1, rgb: [0, 0, 0]},
        ],
        nshades: 100, 
        format: 'rgba'
      });
    }
    const mapData = modelData.map(row => {return {
      hex: row.hex,
      value: travelModes.length == 0
        ? (row.data['cum_dist'].at(-1) ?? 0) / row.population
        : travelModes.map(travelMode => row.data[`cum_dist_${travelMode}`].at(-1) ?? 0).reduce((a, b) => a + b) / row.population,
      data: row.data
    }})
    const maxValue = mapData.map(row => row.value).sort((a, b) => a - b).at(Math.round(mapData.length - (mapData.length * 0.1)))
    return new H3HexagonLayer({
      id: 'hexLayer',
      data: mapData,
      pickable: true,
      wireframe: false,
      filled: true,
      extruded: false,
      getHexagon: d => d.hex,
      //@ts-expect-error doesn't like the slice for some reason
      getFillColor: d => cmap[Math.min(99, Math.max(0, Math.floor((d.value / maxValue) * 100)))].slice(0, 3).concat(hoveredHex == d.hex ? 255 : 150),
      onClick: (d) => {
        if (selectedHexes.includes(d.object?.hex)){
          return
        }
        setSelectedHexes(selectedHexes.concat(d.object?.hex))
        animate(0, 1, {
          duration: 0.5,
          ease: 'easeInOut',
          onUpdate: animateAmount => {setBarHexesAnimateStatus(previousBarHexsAnimateStatus => Object.assign({...previousBarHexsAnimateStatus}, {[d.object?.hex]: animateAmount}))}
        })
        hideTooltip()
      },
      onHover: (d) => {
        setHoveredHex(d.object?.hex)
        if(d.coordinate && d.object){
          const position = mapRef.current?.project(d.coordinate as LngLatLike)
          showTooltip({
            tooltipLeft: position?.x, 
            tooltipTop: position?.y,
            tooltipData: {
              total: Math.round(d.object.value),
              travelModeData: null,
            }
          })
        } else {
          hideTooltip()
        }
      },  
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelData, hoveredHex, selectedHexes, hideTooltip, showTooltip, selectedTravelModes, hoveredTravelMode]);

  const barHexLayer = useMemo(() => {  
    const data = modelData?.filter(row => Object.keys(barHexsAnimateStatus).includes(row.hex)).flatMap(row => {
      let cumulativeHeight = 0
      const animatePercent = barHexsAnimateStatus[row.hex]
      const hexData = (hoveredTravelMode ? [hoveredTravelMode] : (selectedTravelModes.length > 0 ? selectedTravelModes : Object.keys(travelModes)))
        .map((travelMode) : [number, string] => {
          const value = (row.data[`cum_dist_${travelMode}`].at(-1) ?? 0) / row.population
          return [value, travelMode]
        })
      // return hexData.map(([value, travelMode]) => {
      return hexData.sort((b, a) => a[0] - b[0]).map(([value, travelMode]) => {
        const baseElevation = cumulativeHeight;
        const height = (value / 3) * animatePercent;
        cumulativeHeight += height
        const color = travelModeColors[travelMode];
        return height > 0
          ? {
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: [cellToBoundary(row.hex, true).map(coord => coord.concat((baseElevation)))],
            },
            properties: {
              total: (row.data.cum_dist.at(-1) ?? 0) / row.population,
              hex: row.hex,
              value: value,
              travelMode: travelMode,
              height: height,
              color: (hexHoveredTravelMode != travelMode && hexHoveredTravelMode != null && hoveredHex == row.hex) ? rgbChangeSaturation(color, 0.5) : color, 
            }
          } as Feature
          : []
      })
    })
    return new GeoJsonLayer({
      id: 'barHexLayer',
      data:  data,
      pickable: true,
      stroked: false,
      filled: true,
      extruded: true,
      getFillColor: d => d.properties?.color,
      getElevation: d => d.properties?.height,
      onClick: (d) => {
        if(selectedHexes.includes(d.object.properties.hex)){
          hideTooltip()
          setHoveredHex(null)
          setHoveredTravelMode(null)
          setSelectedHexes(selectedHexes.filter(hex => hex != d.object.properties.hex))
          animate(barHexsAnimateStatus[d.object.properties.hex], 0, {
            duration: 0.5,
            ease: 'easeInOut',
            onUpdate: animateAmount => {
              if (animateAmount == 0){
                
                setBarHexesAnimateStatus(previousBarHexsAnimateStatus => {
                  const copyRemoveHex = {...previousBarHexsAnimateStatus}
                  delete copyRemoveHex[d.object.properties.hex]
                  return copyRemoveHex
                })
                return
              }
              setBarHexesAnimateStatus(previousBarHexsAnimateStatus => Object.assign({...previousBarHexsAnimateStatus}, {[d.object.properties.hex]: animateAmount}))
            }
          })
        }
      },
      onHover: (d) => {
        setHoveredHex(d.object ? d.object.properties.hex : null)
        if(d.coordinate && d.object){
          const position = mapRef.current?.project(d.coordinate as LngLatLike)
          setHexHoveredTravelMode(d.object.properties.travelMode)
          showTooltip({
            tooltipLeft: position?.x, 
            tooltipTop: position?.y,
            tooltipData: {
              total: d.object.properties.total,
              travelModeData: {
                travelMode: d.object.properties.travelMode,
                value: d.object.properties.value,
              }
            }
          })
        } else {
          hideTooltip()
          setHexHoveredTravelMode(null)
        }
      },
    });
  }, [modelData, barHexsAnimateStatus, travelModes, selectedTravelModes, travelModeColors, hexHoveredTravelMode, hoveredTravelMode, hoveredHex, selectedHexes, hideTooltip, showTooltip]);

  const skyLayer: SkyLayer = {
    id: 'sky',
    type: 'sky',
    paint: {
      'sky-type': 'atmosphere',
      'sky-atmosphere-sun': [0.0, 0.0],
      'sky-atmosphere-sun-intensity': zoom
    }
  };

  useMemo(() => {
    if(!mapFlying){
      if(locationDropdownValue == 'Kelowna' && currentLocation != kelowna){
        setCurrentLocation(kelowna)
      }
      if(locationDropdownValue == 'Vancouver' && currentLocation != vancouver){
        setCurrentLocation(vancouver)
      }
    }
  }, [currentLocation, kelowna, locationDropdownValue, mapFlying, vancouver])

  useMemo(() => {
    if(mapRef.current == undefined){return}
    setMaxBounds(undefined)
    setMapFlying(true)
    mapRef.current?.flyTo({
      center: {
        lng: currentLocation.lng, 
        lat: currentLocation.lat
      }, 
      zoom: currentLocation.zoom,
      pitch: 0,
      bearing: 0
    })
  }, [currentLocation])

  function onMapMoveEnd(){
    if(mapFlying){
      setMaxBounds(currentLocation.bounds)
      setMapFlying(false)
      mapRef.current?.easeTo({
        pitch: 50,
        bearing: 0
      })
    }
  }

  const onMapLoad = useCallback(() => {
    mapRef.current?.on('move', () => {
      if(mapRef.current){
        setLng(mapRef.current.getCenter().lng);
        setLat(mapRef.current.getCenter().lat);
        setZoom(mapRef.current.getZoom());
      }
    });
  }, []);

  return (
    <div ref={mapMeasureRef} className='map-container' style={{pointerEvents: mapFlying ? 'none' : 'auto'}}>
      <MapboxMap
        id='map'
        ref={mapRef}
        interactive={true}
        interactiveLayerIds={['hexDataLayer', 'barHexLayer']}
        onLoad={onMapLoad}
        onMoveEnd={onMapMoveEnd}
        onMoveStart={() => setHoveredHex(null)}
        mapboxAccessToken="pk.eyJ1Ijoic3R1bWNnIiwiYSI6ImNrcDRtdHE1ZjBiYTkyeHQ4NG5sc3VpM2MifQ.N_cSsEXlTBk7q92Xdpfbug"
        initialViewState={{
          longitude: lng,
          latitude: lat,
          zoom: zoom,
        }}
        maxBounds={maxBounds}
        mapStyle="mapbox://styles/stumcg/clwl4zrxd00j801rb65kc9ui7"
      >
        <Layer {...skyLayer} />
        <DeckGLOverlay interleaved={true} layers={[hexLayer, barHexLayer]} />
        <NavigationControl position='bottom-left' visualizePitch={true}/>
        <FullscreenControl position='bottom-left'/>
        <div className='map-controls' style={{'display': 'flex'}}>
          <div style={{position: 'relative'}}>
            {tooltipOpen && tooltipData && !tooltipData.travelModeData && (
              <TooltipWithBounds left={tooltipLeft} top={tooltipTop}>
                <div style={{'width': 'max-content'}}>
                  <p>Avg {Math.round(tooltipData.total / 10) / 100}Km / person</p>
                </div>
              </TooltipWithBounds>
            )}
            {tooltipOpen && tooltipData && tooltipData.travelModeData && (
              <TooltipWithBounds left={tooltipLeft} top={tooltipTop}>
                <div style={{'width': 'max-content'}}>
                  <p>Avg {Math.round(tooltipData.total / 10) / 100}Km / person</p>
                  <p>
                    <span style={{'color': rgbArrayToHexString(travelModeColors[tooltipData.travelModeData.travelMode])}}>
                      {tooltipData.travelModeData.travelMode}: {
                        tooltipData.travelModeData.value / tooltipData.total < 0.01
                          ? Math.round(tooltipData.travelModeData.value / tooltipData.total * 10000) / 100
                          : Math.round(tooltipData.travelModeData.value / tooltipData.total * 100)
                        }%
                    </span>
                  </p>
                </div>
              </TooltipWithBounds>
            )}
          </div>
          <div style={{'flex': 'auto'}}>
            <span style={{'fontSize': '20px'}}>
              <div style ={{'float': 'left', textAlign: 'left'}}>
                <div className='dropdown-container'>
                  <Dropdown
                    name={'Location'}
                    options={['Kelowna', 'Vancouver']}
                    value={locationDropdownValue}
                    onChange={(value) => setLocationDropdownValue(value)}
                    anyOption={false}
                  />
                </div>
                <div className='dropdown-container'>
                  <Dropdown
                    name={'Zoom'}
                    options={['7', '8', '9']}
                    value={zoomLevelDropdownValue}
                    onChange={(value) => {if(value != null) setZoomLevelDropdownValue(value)}}
                    anyOption={false}
                  />
                </div>
                <br/>
                <Button text={'Select All'} onClick={() => setSelectedHexes((modelData ?? []).map(row => {
                  animate(0, 1, {
                    duration: 0.5,
                    ease: 'easeInOut',
                    onUpdate: animateAmount => {setBarHexesAnimateStatus(previousBarHexsAnimateStatus => Object.assign({...previousBarHexsAnimateStatus}, {[row.hex]: animateAmount}))}
                  })
                  return row.hex
                }))}/>
                <br/>
                <Button text={'Deselect All'} onClick={() => {
                  selectedHexes.map(hex => {
                    animate(barHexsAnimateStatus[hex], 0, {
                      duration: 0.5,
                      ease: 'easeInOut',
                      onUpdate: animateAmount => {
                        if (animateAmount == 0){
                          
                          setBarHexesAnimateStatus(previousBarHexsAnimateStatus => {
                            const copyRemoveHex = {...previousBarHexsAnimateStatus}
                            delete copyRemoveHex[hex]
                            return copyRemoveHex
                          })
                          return
                        }
                        setBarHexesAnimateStatus(previousBarHexsAnimateStatus => Object.assign({...previousBarHexsAnimateStatus}, {[hex]: animateAmount}))
                      }
                    })
                  })
                  setSelectedHexes([])
                }}/>
              </div>
              <br />
            </span>
          </div>
          <div style={{display: 'block'}}>
            <div style={{
                'float': 'right',
                'display': 'grid',
                // 'gridTemplateColumns': `repeat(3, ${infoBoxesSize+4}px)`,
                'gridTemplateColumns': (mapMeasure.width ?? 0) > 1300 ? `repeat(6, ${infoBoxesSize+4}px)` : `repeat(3, ${infoBoxesSize+4}px)`,
                'gridAutoRows': `${infoBoxesSize+4}px`,
                'height': 'fit-content',
                'direction': 'rtl',
                'margin': '5px',
                'pointerEvents': 'auto'
            }}>
              {
                Object.keys(travelModes).map(travelMode => {return (
                  <div 
                    style={{
                      'backgroundColor': hoveredTravelMode == travelMode ? rgbArrayToHexString(travelModeColors[travelMode]) : 'transparent',
                      'height': `${infoBoxesSize+4}px`,
                      'width': `${infoBoxesSize+4}px`,
                      'cursor': 'pointer', 
                      'userSelect': 'none',
                      'direction': 'ltr',
                    }}
                    onClick={() => {
                      if(selectedTravelModes.includes(travelMode)){
                        setSelectedTravelModes(selectedTravelModes.filter(mode => mode != travelMode))
                        setHoveredTravelMode(null)
                      } else {
                        setSelectedTravelModes(selectedTravelModes.concat(travelMode))
                      }
                    }}
                    onMouseEnter={() => {
                      if(selectedTravelModes.length == 0){
                        setHoveredTravelMode(travelMode)
                      }
                    }}
                    onMouseLeave={() => {
                      setHoveredTravelMode(null)
                    }}
                  >
                    <div 
                      style={{
                        'backgroundColor': selectedTravelModes.length > 0 && !selectedTravelModes.includes(travelMode)
                          ? 'grey'
                          : (
                            hoveredTravelMode != null && hoveredTravelMode != travelMode
                            ? rgbArrayToHexString(rgbChangeSaturation(travelModeColors[travelMode], 0.5))
                            : rgbArrayToHexString(travelModeColors[travelMode])
                          ),
                        'height': `${infoBoxesSize}px`,
                        'width': `${infoBoxesSize}px`,
                        'margin': '2px',
                      }}
                    >
                      {infoBoxesAnimateStatus == 1 && infoBoxData != null && (
                        <div style={{color: 'white', textAlign: 'left', margin: '2px'}}>
                          <div style={{height: '4px'}}/>
                          <div style={{fontSize: '35px', lineHeight: '30px', width: '100%', margin: '2px'}}>
                            {travelModes[travelMode].charAt(0).toUpperCase() + travelModes[travelMode].slice(1)}
                          </div>
                          <div style={{width: '100%', margin: '2px'}}>
                            <div style={{display: 'inline-block', fontSize: '45px', lineHeight: '50px', width: 'min-content'}}>
                                <>
                                  {Math.round(infoBoxData[travelMode].distPercent * 100)}%
                                </>
                            </div>
                            <div style={{display: 'inline-block', fontSize: '20px', lineHeight: '20px', width: 'min-content'}}>
                              of distance
                            </div>
                          </div>
                          <div style={{fontSize: '25px', lineHeight: '25px', width: '100%', margin: '2px'}}>
                              {
                                infoBoxData[travelMode].tripsPercent < 0.01
                                ? Math.round(infoBoxData[travelMode].tripsPercent * 1000) / 10
                                : Math.round(infoBoxData[travelMode].tripsPercent * 100)
                              }% of trips
                          </div>
                          <div style={{fontSize: '25px', lineHeight: '25px', width: '100%', margin: '2px'}}>
                              {Math.round(infoBoxData[travelMode].distancePerTrip / 100) / 10} Km/trip
                          </div>
                          <div style={{fontSize: '25px', lineHeight: '25px', width: '100%', margin: '2px'}}>
                              {
                                infoBoxData[travelMode].c02Percent < 0.01
                                ? Math.round(infoBoxData[travelMode].c02Percent * 1000) / 10
                                : Math.round(infoBoxData[travelMode].c02Percent * 100)
                              }% of C02
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )})
              }
              <svg style={{margin: '2px'}} width={infoBoxesSize} height={infoBoxesSize} xmlns="http://www.w3.org/2000/svg">
                <polygon 
                  style={{cursor: 'pointer'}}
                  fill='black'
                  points={
                    (mapMeasure.width ?? 0) < 1300
                    ? `0,0 ${infoBoxesSize},${infoBoxesSize} ${infoBoxesExpanded? `${infoBoxesSize},0` : `0,${infoBoxesSize}`}`
                    : infoBoxesExpanded 
                      ? `${infoBoxesSize/2},0 ${infoBoxesSize/2},${infoBoxesSize} ${infoBoxesSize},${infoBoxesSize/2}` 
                      : `${infoBoxesSize},0 ${infoBoxesSize},${infoBoxesSize} ${infoBoxesSize/2},${infoBoxesSize/2}`
                  } 
                  // points={`0,0 ${infoBoxesSize},${infoBoxesSize} ${infoBoxesExpanded? `${infoBoxesSize},0` : `0,${infoBoxesSize}`}`}
                  // points={
                  //   infoBoxesExpanded 
                  //   ? `${infoBoxesSize/2},0 ${infoBoxesSize/2},${infoBoxesSize} ${infoBoxesSize},${infoBoxesSize/2}` 
                  //   : `${infoBoxesSize},0 ${infoBoxesSize},${infoBoxesSize} ${infoBoxesSize/2},${infoBoxesSize/2}`
                  // }
                  onClick={() => {
                    setInfoBoxesExpanded(!infoBoxesExpanded)
                      animate(infoBoxesAnimateStatus, infoBoxesExpanded ? 0 : 1, {
                        duration: 0.5,
                        ease: 'easeInOut',
                        onUpdate: animateAmount => {setInfoBoxesAnimateStatus(animateAmount)}
                      })
                  }}
                />
              </svg>
            </div>
            <div 
              style={{
                float: 'right',
                height: `${chartBoxHeight}px`,
                width: `${chartBoxWidth}px`,
                backgroundColor: 'white',
                clear: 'right',
                margin: '5px',
                marginTop: '0',
                cursor: 'pointer',
                pointerEvents: 'auto'
              }}
              onClick={() => {
                setChartBoxExpanded(!chartBoxExpanded)
                  animate(chartBoxAnimationSatus, chartBoxAnimationSatus ? 0 : 1, {
                    duration: 0.5,
                    ease: 'easeInOut',
                    onUpdate: animateAmount => {setChartBoxAnimationStatus(animateAmount)}
                  })
              }}
            >
              {chartBoxAnimationSatus == 0 && 
                Object.keys(travelModes).map((travelMode, index) => {
                  const barHeights = [0.45, 0.85, 0.15, 0.6, 0.75]
                  return(
                    <div style={{
                      backgroundColor: rgbArrayToHexString(travelModeColors[travelMode]),
                      width: `${(chartBoxWidth * 0.75)/Object.keys(travelModes).length}px`,
                      height:`${chartBoxHeight * barHeights[index]}px`,
                      float: 'left',
                      marginTop: `${chartBoxHeight - (chartBoxHeight * barHeights[index])}px`,
                      marginLeft: `${(chartBoxWidth * 0.25)/(Object.keys(travelModes).length + 1)}px`
                    }}/>
                  )
                })
              }
              {chartBoxAnimationSatus != 0 && barChartData != null && (
                <>
                  <div style={{
                    width:`${chartBoxWidth}px`,
                    height: `${chartBoxHeight * 0.1}px`,
                    textAlign: 'center',
                    verticalAlign: 'middle',
                    lineHeight: `${chartBoxHeight * 0.1}px`,
                    fontSize: '15px'
                  }}>
                    {chartBoxAnimationSatus == 1 && [
                      {stat: 'dist', title: 'Total Distance'},
                      {stat: 'trips', title: 'Number of trips'},
                      {stat: 'c02', title: 'Total C02'}
                    ].map(s => {return(
                      <div 
                        style={{
                          width: '33%',
                          display: 'inline-block',
                          fontWeight: currentBarChartStat == s.stat ? 'bold' : 'lighter',
                          color: currentBarChartStat == s.stat ? 'black' : 'grey'
                        }}
                        onClick={(e) => {
                          e.stopPropagation()
                          setCurrentBarChartStat(s.stat)
                        }}
                      >
                        {s.title}
                      </div>
                    )})}
                  </div>
                  <BarChart width={chartBoxWidth} height={chartBoxHeight * 0.9} data={barChartData}/>
                </>
              )}
            </div>
          </div>
        </div>
      </MapboxMap>
    </div>
  )
}

function HexDistances(){
  return (
    <>
      <div style={{'height': '100vh', 'width': '100vw'}}>
        <HexDistancesMap/>
      </div>
    </>
  )
}

export default HexDistances
