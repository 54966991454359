import './css/App.css'
import { Routes, Route } from 'react-router-dom'
import Contact from './Contact.tsx'
import Data from './Data.tsx'
import Home from './Home.tsx'
import Login from './Login.tsx'
import RequestAccess from './RequestAccess.tsx'
import UserPage from './UserPage.tsx'
import MapPage from './MapPage.tsx'
import Scratch from './Scratch.tsx'
import Admin from './Admin.tsx'
import ForgotPassword from './ForgotPassword.tsx'
import RecoverAccount from './RecoverAccount.tsx'
import Census from './Census.tsx'
import ModelHex from './maps/ModelHex.tsx'
import ModelHex2 from './maps/ModelHex2.tsx'
import OldMaps from './OldMaps.tsx'
import ModelHex3 from './maps/ModelHex3.tsx'
import NewHome from './NewHome.tsx'
import HexDistances from './maps/HexDistances.tsx'

function App() {
  return (
    <Routes>
      <Route path='/OldHome' element={ <Home />} />
      <Route path='/Contact' element={ <Contact />} />
      <Route path='/Data' element={ <Data />} />
      <Route path='/Map' element={ <MapPage />} />
      <Route path='/Census' element={ <Census />} />
      <Route path='/ModelHex' element={ <ModelHex />} />
      <Route path='/ModelHex2' element={ <ModelHex2 />} />
      <Route path='/ModelHex3' element={ <ModelHex3 />} />
      <Route path='/HexDistances' element={ <HexDistances />} />
      <Route path='/Login' element={ <Login />} />
      <Route path='/RequestAccess' element={ <RequestAccess />} />
      <Route path='/ForgotPassword' element={ <ForgotPassword />} />
      <Route path='/RecoverAccount' element={ <RecoverAccount />} />
      <Route path='/UserPage' element={ <UserPage />} />
      <Route path='/Admin' element={ <Admin />} />
      <Route path='/Scratch' element={ <Scratch />} />
      <Route path='/OldMaps' element={ <OldMaps />} />
      <Route path='/' element={ <NewHome />} />
    </Routes>
  )
}

export default App
